import Vue from 'vue';
// convert Free to 0₫
const free20Filter = Vue.filter('free20', (value: string) => {
  if (
    value &&
    (value.toLowerCase() === 'free' || value.toLowerCase() === 'miễn phí')
  ) {
    return '0 ₫';
  } else {
    return value;
  }
});

export default free20Filter;
