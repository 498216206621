<template>
  <v-expand-transition>
    <v-footer fixed height="110" color="rgba(232, 234, 246, 0.1)">
      <v-row justify="center" class="align-self-start ma-0 pa-0">
        <v-btn
          max-width="650"
          width="100%"
          large
          color="primary lighten-2"
          @click="showPreviewCartSheet()"
          class="pa-2"
        >
          <v-avatar left class="indigo darken-4" size="24">
            {{ noOfItems }}
          </v-avatar>
          <v-spacer></v-spacer> Xem giỏ hàng<v-spacer></v-spacer>

          <span class="text-body-2">
            {{ totalPrice | vnd | free20 }}
          </span>
        </v-btn>
      </v-row>
    </v-footer>
  </v-expand-transition>
</template>

<script lang="ts">
import { IShoppingCart } from '@/interfaces';
import {
  Component,
  Vue,
  Prop,
  Emit,
  Model,
  Watch,
} from 'vue-property-decorator';

@Component
export default class PreviewCartButton extends Vue {
  @Prop({ default: 0 }) public totalPrice!: number;
  @Prop({ default: 0 }) public noOfItems!: number;

  @Emit('preview')
  showPreviewCartSheet() {
    return true;
  }
}
</script>

<style scoped></style>
