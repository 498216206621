<template>
  <div id="app">
    <v-app
      style="min-height: 80vh"
      background-color="indigo lighten-4"
      :style="{ background: $vuetify.theme.themes.light.background }"
    >
      <v-main> </v-main>
      <router-view />
      <v-snackbar
        bottom
        right
        :value="updateExists"
        :timeout="-1"
        color="primary"
      >
        Muua - Đã có phiên bản mới. Ấn <b>Làm mới</b> để cập nhật
        <v-btn text @click="refreshApp"> Làm mới </v-btn>
      </v-snackbar>
      <NotificationsManager></NotificationsManager>
    </v-app>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import NotificationsManager from '@/components/NotificationsManager.vue';

@Component({
  components: {
    NotificationsManager,
  },
})
export default class App extends Vue {
  refreshing = false;
  registration?: ServiceWorkerRegistration;

  updateExists = false;

  public async created() {
    // Listen for our custom event from the SW registration
    document.addEventListener('swUpdated', this.updateAvailable, {
      once: true,
    });

    // Prevent multiple refreshes
    navigator.serviceWorker.addEventListener('controllerchange', () => {
      if (this.refreshing) return;
      this.refreshing = true;
      // Here the actual reload of the page occurs
      window.location.reload();
    });
  }

  // Store the SW registration so we can send it a message
  // We use `updateExists` to control whatever alert, toast, dialog, etc we want to use
  // To alert the user there is an update they need to refresh for
  updateAvailable(event) {
    this.registration = event.detail;
    this.updateExists = true;
  }

  // Called when the user accepts the update
  refreshApp() {
    this.updateExists = false;
    // Make sure we only send a 'skip waiting' message if the SW is waiting
    if (!this.registration || !this.registration.waiting) return;
    // send message to SW to skip the waiting and activate the new SW
    this.registration.waiting.postMessage({ type: 'SKIP_WAITING' });
  }
}
</script>
