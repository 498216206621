const env = process.env.VUE_APP_ENV;

let envApiUrl = '';

if (env === 'production') {
  envApiUrl = `https://${location.hostname}`;
} else if (env === 'staging') {
  envApiUrl = `https://${location.hostname}`;
} else {
  envApiUrl = `http://${location.hostname}`;
}

export const apiUrlV1 = `${envApiUrl}/api/v1/shop`;
export const appName = process.env.VUE_APP_NAME;
export const CF_IMAGES_ACC_HASH = 'zXHPVumwuJUE8Wbecl7KWA';
export const IMAGES_VARIANT_PUBLIC = 'public';
export const IMAGES_VARIANT_THUMBNAIL = 'thumbnail';
