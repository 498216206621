<template>
  <div v-html="formattedUserInput" class="pre-line"></div>
</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator';

@Component
export default class FormattedView extends Vue {
  get formattedUserInput(): string {
    return this.$slots.default && this.$slots.default[0].text
      ? this.$slots.default[0].text.replace(/\n/g, '<br/>')
      : '';
  }
}
</script>

<style scoped>
.pre-line {
  white-space: pre-line;
}
</style>
