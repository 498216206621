<template>
  <v-card flat class="pa-2">
    <v-card-text class="primary--text text--darken-3">
      <v-row class="text-h6">
        {{ question ? question.content : '' }}
      </v-row>
      <v-row v-show="!question.answer_required">
        <span class="text-body-1 testQuestions">Tuỳ chọn</span>
      </v-row>
      <template v-if="question && !question.multiple_choice">
        <v-row v-show="!question.multiple_choice">
          <v-text-field
            hide-details="auto"
            v-model="answers[0].answer_content"
            single-line
            placeholder="Nhập vào đây"
            :rules="
              question.answer_required && !question.multiple_choice
                ? requiredRules
                : []
            "
          ></v-text-field>
        </v-row>
      </template>
      <template
        v-if="
          question.multiple_choice &&
          question.choices &&
          question.choices.length > 0 &&
          answers.length > 1
        "
      >
        <v-container
          class="pa-0 mt-4"
          fluid
          v-for="(answer, index) in answers"
          :key="index"
        >
          <v-card
            class="hoverable-sheet"
            :outlined="answer.selected"
            :class="answer.selected ? 'selected-sheet' : ''"
            link
            @click="select(answer)"
            :disabled="isLimitOfSelectionReached() && !answer.selected"
          >
            <v-card-text class="ma-0 pa-0">
              <v-row class="text-body-1" no-gutters align="end" justify="end">
                <v-col cols="auto" align-self="center" class="pa-2"
                  ><span class="font-weight-bold">{{
                    answer.choice.content
                  }}</span></v-col
                >
                <v-spacer v-show="!answer.selected"></v-spacer>

                <v-col cols="auto" align-self="center" class="pa-2">
                  <span>+ {{ answer.choice.surcharge | vnd }}</span>
                </v-col>
                <v-spacer v-show="answer.selected"></v-spacer>
                <v-col
                  cols="auto"
                  class="pa-2"
                  align-self="end"
                  v-show="answer.selected"
                  @click.stop="stop"
                >
                  <v-btn
                    icon
                    @click="decreaseQuantity(answer)"
                    :disabled="answer.quantity === 0"
                  >
                    <v-icon>{{ svgPath.mdiMinusBoxOutline }}</v-icon></v-btn
                  ><span> {{ answer.quantity }} </span
                  ><v-btn
                    icon
                    @click="increaseQuantity(answer)"
                    :disabled="
                      (answer.quantity ===
                        question.limitSelectionOfEachChoice &&
                        question.limitSelectionOfEachChoice > 0) ||
                      isLimitOfSelectionReached()
                    "
                  >
                    <v-icon> {{ svgPath.mdiPlusBoxOutline }}</v-icon></v-btn
                  >
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-container>

        <v-input
          :rules="minimumCustomizationRules"
          v-show="!validateMinimumCustomization()"
        >
        </v-input>
      </template>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import {
  EAllowedChoiceSelection,
  IChoice,
  IItemCustomization,
  IQuestion,
} from '@/interfaces';
import {
  Component,
  Vue,
  Prop,
  Emit,
  Model,
  Watch,
} from 'vue-property-decorator';
import { mdiPlusBoxOutline, mdiMinusBoxOutline } from '@mdi/js';
@Component
export default class QuestionSheet extends Vue {
  @Prop() public question: IQuestion | undefined;
  @Model('update:answers', { type: Array })
  public answers!: IItemCustomization[];

  svgPath = {
    mdiPlusBoxOutline,
    mdiMinusBoxOutline,
  };

  requiredRules = [(v) => !!v || 'Vui lòng điền giá trị'];

  minimumCustomizationRules = [
    (
      v = { quantity: this.getSelectedQuantity(), question: this.getQuestion() }
    ) => {
      if (
        v &&
        v.question &&
        v.quantity &&
        this.validateMinimumCustomization(v.quantity, v.question!)
      ) {
        return true;
      } else {
        return `Vui lòng chọn ít nhất ${v.question?.min_allowed_quantity_selection} lựa chọn)`;
      }
    },
  ];

  validateMinimumCustomization(
    quantity: number,
    question: IQuestion | undefined
  ) {
    if (!quantity) {
      quantity = this.getSelectedQuantity();
    }
    if (!question) {
      question = this.getQuestion();
    }
    if (
      question &&
      question.min_allowed_quantity_selection &&
      question.min_allowed_quantity_selection > 0 &&
      quantity < question.min_allowed_quantity_selection
    ) {
      return false;
    } else {
      return true;
    }
  }

  getQuestion() {
    return this.question;
  }

  increaseQuantity(customization: IItemCustomization) {
    customization.quantity++;
  }
  decreaseQuantity(customization: IItemCustomization) {
    if (customization.quantity > 1) {
      customization.quantity--;
    } else {
      this.select(customization);
    }
  }

  isLimitOfSelectionReached() {
    let isLimited = false;
    if (this.question?.max_allowed_quantity_selection === 0) {
      isLimited = false;
    } else {
      isLimited =
        this.getSelectedQuantity() >=
        this.question?.max_allowed_quantity_selection!;
    }
    return isLimited;
  }

  getSelectedQuantity() {
    let selectedQuantity = 0;
    for (const c of this.answers) {
      selectedQuantity += c.quantity;
    }
    return selectedQuantity;
  }

  select(customization: IItemCustomization) {
    customization.selected = !customization.selected;
    if (customization.selected) {
      customization.quantity = 1;
    } else {
      customization.quantity = 0;
    }
  }
  stop() {
    //console.log('stop');
  }
}
</script>

<style scoped>
.hoverable-sheet:hover {
  background-color: rgb(216, 216, 247);
}

.selected-sheet {
  border: 2px solid #3949ab;
  border-color: #3949ab;
}
</style>
