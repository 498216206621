<template>
  <v-dialog
    transition="dialog-bottom-transition"
    max-width="600"
    v-model="dialog"
    :persistent="$vuetify.breakpoint.smAndUp ? true : false"
    :fullscreen="$vuetify.breakpoint.smAndUp ? false : true"
    class="primary--text text--darken-3"
    content-class="rounded-lg"
    scrollable
  >
    <template>
      <v-form
        id="addToCartForm"
        v-async-form
        v-model="valid"
        ref="form"
        @submit.prevent="submit"
        lazy-validation
      >
        <v-card>
          <v-app-bar flat color="rgba(0, 0, 0, 0)" absolute>
            <v-spacer></v-spacer>
            <v-btn
              class="mr-2 transparent-button"
              color="grey darken-1"
              icon
              dark
              small
              @click="resetAndClose"
              elevation="2"
            >
              <v-icon>{{ svgPath.mdiClose }}</v-icon>
            </v-btn>
          </v-app-bar>

          <v-card-text
            class="primary--text text--darken-3 text-justify"
            style="padding: 0"
          >
            <v-carousel
              v-if="product"
              hide-delimiter-background
              :delimiter-icon="svgPath.mdiCircleMedium"
              cycle
              show-arrows-on-hover
              height="44vh"
            >
              <v-carousel-item v-for="photo in thumbnails" :key="photo.uuid">
                <v-sheet tile>
                  <v-row class="fill-height" align="center" justify="center">
                    <v-img
                      v-if="photo.uuid"
                      :src="getImageUrl(photo.uuid)"
                      :lazy-src="require('../assets/default-loading-image.png')"
                      :contain="containImage"
                      height="44vh"
                      ><template v-slot:placeholder>
                        <v-row
                          class="fill-height ma-0"
                          align="center"
                          justify="center"
                        >
                          <v-progress-circular
                            indeterminate
                            color="grey lighten-5"
                          ></v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                  </v-row>
                </v-sheet>
              </v-carousel-item>
              <v-btn
                icon
                absolute
                bottom
                tile
                dark
                left
                color="grey darken-1"
                elevation="2"
                @click="containImage = !containImage"
                class="transparent-button mb-1"
              >
                <v-icon>{{
                  containImage ? svgPath.mdiArrowExpandAll : svgPath.mdiOverscan
                }}</v-icon>
              </v-btn>
            </v-carousel>
            <div style="padding: 16px">
              <v-row class="text-h5 pa-2 justify-center text-center"
                >{{ product ? product.name : '' }}
                {{ variant ? ', ' + variant.name : '' }}</v-row
              >
              <v-row class="text-h5 mb-2 pa-2 justify-center"
                >{{
                  variant
                    ? variant.unit_price
                    : product
                    ? product.unit_price
                    : 0 | vnd
                }}
              </v-row>
              <v-row
                class="ma-2 text-body-1"
                v-show="variant && variant.description"
              >
                <truncated-text
                  :fullText="variant?.description"
                  :maxLength="300"
                ></truncated-text>
              </v-row>
              <v-row
                class="ma-2 text-h6"
                v-show="
                  variant &&
                  variant.description &&
                  product &&
                  product.description &&
                  showProductDescription
                "
              >
                {{ product ? product.name : '' }}
              </v-row>
              <v-row
                class="ma-2 text-body-1"
                v-show="
                  product && product.description && showProductDescription
                "
              >
                <truncated-text
                  :fullText="product?.description"
                  :maxLength="300"
                ></truncated-text>
              </v-row>
              <v-divider
                v-show="!questions || questions.length === 0"
              ></v-divider>
              <v-sheet outlined>
                <question-sheet
                  v-for="(question, index) in questions"
                  :question="question"
                  v-model="question.answers"
                  :key="index"
                  class="my-4"
                ></question-sheet>
              </v-sheet>

              <v-row justify="center" class="mt-4 mb-2 pa-2">
                <v-col cols="5" align-self="end"
                  ><v-row justify="end">
                    <v-btn
                      @click="decreaseProductQuantity()"
                      large
                      outlined
                      icon
                      tile
                      :disabled="
                        productQuantity <= 1 || isDecreaseBtnDisabled()
                      "
                    >
                      <v-icon>{{ svgPath.mdiMinus }}</v-icon>
                    </v-btn></v-row
                  >
                </v-col>

                <v-col cols="2" align-self="center"
                  ><v-row justify="center"
                    ><span class="text-h5">{{ productQuantity }}</span></v-row
                  ></v-col
                >
                <v-col cols="5"
                  ><v-row justify="start">
                    <v-btn
                      large
                      outlined
                      icon
                      tile
                      @click="increaseProductQuantity()"
                      :disabled="isIncreaseBtnDisabled()"
                    >
                      <v-icon>{{ svgPath.mdiPlus }}</v-icon>
                    </v-btn></v-row
                  >
                </v-col>
              </v-row>
              <v-row class="ma-4" justify="center">
                <v-btn
                  x-large
                  block
                  color="primary lighten-2"
                  @click="addToCart()"
                  >Thêm vào giỏ hàng</v-btn
                >
              </v-row>
            </div>
          </v-card-text>
        </v-card>
      </v-form>
    </template>
  </v-dialog>
</template>

<script lang="ts">
import {
  IProduct,
  IProductVariant,
  IQuestion,
  IShoppingCart,
} from '@/interfaces';
import {
  Component,
  Vue,
  Prop,
  Emit,
  Model,
  Watch,
} from 'vue-property-decorator';
import {
  mdiClose,
  mdiPlus,
  mdiMinus,
  mdiArrowExpandAll,
  mdiOverscan,
  mdiCircleMedium,
} from '@mdi/js';
import { IShoppingCartItem, IPhoto, IItemCustomization } from '@/interfaces';
import { getImageUrl, getThumbnailUrl } from '@/utils';
@Component
export default class AddToCartDialog extends Vue {
  @Prop() product: IProduct | undefined;
  @Prop() variant: IProductVariant | undefined;
  @Prop() shoppingCart: IShoppingCart | undefined;
  @Model() dialog!: boolean;

  // prevent showing the dialog when the product is not defined
  // normally this should not happen, but just in case user click faster than the
  // timout after closing dialog when product is reset to undefined
  @Watch('product')
  onProductChange(newValue: IProduct | undefined) {
    if (!newValue) {
      //this.resetAndClose();
    } else {
      this.reset();
    }
  }

  resetValidation() {
    if (this.$refs.form) {
      (
        this.$refs.form as Vue & { resetValidation: () => boolean }
      ).resetValidation();
    }
  }

  @Watch('variant')
  onVariantChange(newValue: IProductVariant | undefined) {
    if (!newValue && !this.product) {
      // this.resetAndClose();
    } else {
      this.reset();
    }
  }

  submit() {}

  @Watch('dialog')
  onDialogChange(newValue: boolean) {
    if (newValue) {
      this.enableScroll(); // enable scroll when dialog is opened
    }
  }

  containImage: boolean = false;
  getImageUrl = getImageUrl;
  public svgPath = {
    mdiClose,
    mdiPlus,
    mdiMinus,
    mdiArrowExpandAll,
    mdiOverscan,
    mdiCircleMedium,
  };

  questions: IQuestion[] = [];
  resetQuestion() {
    this.questions = [
      {
        content: 'What is your name?',
        answer_required: true,
        multiple_choice: false,
        allowed_selection: 'Unlimited',
      },
      {
        content: 'What is your age?',
        answer_required: true,
        multiple_choice: true,
        allowed_selection: 'One',
        min_allowed_quantity_selection: 2,
        max_allowed_quantity_selection: 19,
        limit_selection_of_each_choice: 3,
        choices: [
          {
            content: '18',
            surcharge: 1,
          },
          {
            content: '19',
            surcharge: 2,
          },
          {
            content: '20',
            surcharge: 3,
          },
        ],
      },
      {
        content: 'What is your favorite color?',
        answer_required: false,
        multiple_choice: false,
        allowed_selection: 'One',
        choices: [
          {
            content: 'Red',
            surcharge: 1,
          },
          {
            content: 'Green',
            surcharge: 2,
          },
          {
            content: 'Blue',
            surcharge: 3,
          },
        ],
      },
    ];

    this.questions = this.product?.questions || [];
  }

  valid = true;

  public validate() {
    return (this.$refs.form as Vue & { validate: () => boolean }).validate();
  }

  reset() {
    this.resetProductDescriptionDisplay();
    this.resetThumbnails();
    this.resetProductQuantity();
    this.resetQuestion();
    this.initAnswers();
    this.resetValidation();
    this.customizations = [];
  }

  resetProductDescriptionDisplay() {
    if (this.variant && this.variant.description) {
      this.showProductDescription = false;
    } else {
      this.showProductDescription = true;
    }
  }

  isIncreaseBtnDisabled() {
    const restrictQuantityChoice = this.getRestrictQuantityChoice() as string[];
    const length = restrictQuantityChoice.length;
    const index = restrictQuantityChoice.indexOf(
      this.productQuantity.toString()
    );
    if (index === length - 1 && restrictQuantityChoice[0] !== '0') {
      return true;
    } else {
      return false;
    }
  }

  isDecreaseBtnDisabled() {
    const restrictQuantityChoice = this.getRestrictQuantityChoice() as string[];
    const index = restrictQuantityChoice.indexOf(
      this.productQuantity.toString()
    );
    if (index === 0) {
      return true;
    } else {
      return false;
    }
  }

  fakeRestriction() {
    if (this.product) {
      this.product!.restrict_quantity_choice = true;
      this.product!.quantity_choices = '2';
    }
  }

  resetThumbnails() {
    this.thumbnails = this.getPhotoThumbnails();
  }

  getRestrictQuantityChoice() {
    if (
      this.product &&
      this.product.restrict_quantity_choice &&
      this.product.quantity_choices
    ) {
      return this.product.quantity_choices.split(',');
    } else {
      return ['0'];
    }
  }

  resetProductQuantity() {
    // TODO: calculate base on stock availability
    if (
      (this.product || this.variant) &&
      this.product?.restrict_quantity_choice &&
      this.product?.quantity_choices
    ) {
      this.productQuantity = parseInt(this.getRestrictQuantityChoice()[0]);
    } else {
      this.productQuantity = 1;
    }
  }

  increaseProductQuantity() {
    if (
      this.product?.restrict_quantity_choice &&
      this.product?.quantity_choices
    ) {
      const restrictQuantityChoice =
        this.getRestrictQuantityChoice() as string[];
      const index = restrictQuantityChoice.indexOf(
        this.productQuantity.toString()
      );
      if (index !== -1 && index < restrictQuantityChoice.length - 1) {
        this.productQuantity = parseInt(restrictQuantityChoice[index + 1]);
      }
    } else {
      this.productQuantity++;
    }
  }

  decreaseProductQuantity() {
    if (
      this.product?.restrict_quantity_choice &&
      this.product?.quantity_choices
    ) {
      const restrictQuantityChoice =
        this.getRestrictQuantityChoice() as string[];
      const index = restrictQuantityChoice.indexOf(
        this.productQuantity.toString()
      );
      if (index !== -1 && index > 0) {
        this.productQuantity = parseInt(restrictQuantityChoice[index - 1]);
      }
    } else {
      this.productQuantity--;
    }
  }

  customizations: IItemCustomization[] = [];

  initAnswers() {
    for (const question of this.questions) {
      const answers: IItemCustomization[] = [];
      if (question && question.choices) {
        for (const c of question.choices) {
          const answer: IItemCustomization = {
            choice: c,
            question_content: question.content,
            quantity: 0,
            total_price: 0,
            answer_content: '',
            selected: false,
          };
          answers.push(answer);
        }
      } else {
        const answer: IItemCustomization = {
          question_content: question.content,
          quantity: 0,
          total_price: 0,
          answer_content: '',
          selected: false,
        };
        answers.push(answer);
      }
      question.answers = answers;
    }
  }

  getCustomizations() {
    this.customizations = [];
    for (const question of this.questions) {
      for (const answer of question.answers ?? []) {
        if (answer.selected || answer.answer_content !== '') {
          this.customizations.push(answer);
        }
      }
    }
  }

  addToCart() {
    if (!this.validate()) {
      return;
    }

    this.getCustomizations();

    const cartItem: IShoppingCartItem = {
      product_id: this.variant ? this.variant.id : this.product!.id,
      quantity: this.productQuantity,
      total_price: this.calculateTotalPrice(),
      thumbnail_url: this.getImageUrl(this.thumbnails[0].uuid!),
      product_name: this.variant?.name || this.product!.name,
      unit_price: this.variant?.unit_price || this.product!.unit_price,
      customizations: this.customizations,
    };
    this.$emit('add', cartItem);
  }

  calculateTotalPrice() {
    let totalPrice = 0;
    if (this.variant) {
      totalPrice = this.productQuantity * this.variant.unit_price;
    } else {
      totalPrice = this.productQuantity * this.product!.unit_price;
    }
    return totalPrice;
  }

  isCloseBtnInvisible: boolean = false;

  onIntersect(entries, observer) {
    // More information about these options
    // is located here: https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
    this.isCloseBtnInvisible = !entries[0].isIntersecting;
  }

  productQuantity: number = 1;
  thumbnails: IPhoto[] = [];

  getPhotoThumbnails() {
    let _thumbnails: IPhoto[] = [];

    if (this.variant && this.variant.images && this.variant.images.length > 0) {
      _thumbnails = this.variant.images;
    }
    let _concatedThumbnails: IPhoto[] = _thumbnails;
    if (this.product && this.product.images && this.product.images.length > 0) {
      _concatedThumbnails = _thumbnails.concat(this.product.images);
    }

    if (_concatedThumbnails.length === 0) {
      return [
        {
          uuid: '',
        },
      ];
    } else {
      return _concatedThumbnails;
    }
  }

  showProductDescription = true;

  async mounted() {
    this.reset();
  }

  async enableScroll() {
    await this.$nextTick();
    const addToCartForm = document.getElementById('addToCartForm');

    addToCartForm?.addEventListener('mousewheel', (e) => {
      e.stopPropagation();
    });
    addToCartForm?.addEventListener('DOMMouseScroll', (e) => {
      e.stopPropagation();
    });
  }

  getCurrentQuantity() {
    let quantity: number = 1;
    // if (this.shoppingCart) {
    //   quantity =
    //     this.shoppingCart.items.find(
    //       (item: IShoppingCartItem) =>
    //         item.productId === this.product!.id &&
    //         item.variantId === this.variant!.id
    //     )?.quantity ?? 1;
    // }

    return quantity;
  }

  @Emit('resetAndClose')
  resetAndClose() {
    this.reset();
    return false;
  }
}
</script>

<style scoped>
.transparent-button {
  background-color: white;
  opacity: 0.7;
  z-index: 99;
}
</style>
<style>
.dialog-bottom-transition-enter-active,
.dialog-bottom-transition-leave-active {
  transition: transform 0.5s ease-in-out;
}
</style>
