import { MainState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const getters = {
  hasAdminAccess: (state: MainState) => {
    return (
      state.userProfile &&
      state.userProfile.is_superuser &&
      state.userProfile.is_active
    );
  },
  hasLandlordAccess: (state: MainState) => {
    return state.userProfile && state.userProfile.is_active;
  },
  loginError: (state: MainState) => state.logInError,
  dashboardShowDrawer: (state: MainState) => state.dashboardShowDrawer,
  dashboardMiniDrawer: (state: MainState) => state.dashboardMiniDrawer,
  userProfile: (state: MainState) => state.userProfile,
  token: (state: MainState) => state.token,
  buyerInfo: (state: MainState) => state.buyerInfo,
  isLoggedIn: (state: MainState) => state.isLoggedIn,
  firstNotification: (state: MainState) =>
    state.notifications.length > 0 && state.notifications[0],

  shopSetting: (state: MainState) => state.shopSetting,
  products: (state: MainState) => {
    // return the products if hidden is false
    return state.products.filter((product) => !product.hidden);
  },
  order: (state: MainState) => state.order,
  paymentMethods: (state: MainState) => state.paymentMethods,
  onePaymentMethod: (state: MainState) => (paymentMethodId: string) => {
    const filteredPaymentMethods = state.paymentMethods.filter(
      (paymentMethod) => paymentMethod.id === paymentMethodId
    );
    if (filteredPaymentMethods.length > 0) {
      return { ...filteredPaymentMethods[0] };
    }
  },
  fulfillmentTemplates: (state: MainState) => state.fulfillmentTemplates,
  categories: (state: MainState) => state.categories,
};

const { read } = getStoreAccessors<MainState, State>('');

export const readDashboardMiniDrawer = read(getters.dashboardMiniDrawer);
export const readDashboardShowDrawer = read(getters.dashboardShowDrawer);
export const readHasAdminAccess = read(getters.hasAdminAccess);
export const readHasLandlordAccess = read(getters.hasLandlordAccess);
export const readIsLoggedIn = read(getters.isLoggedIn);
export const readLoginError = read(getters.loginError);
export const readToken = read(getters.token);
export const readBuyerInfo = read(getters.buyerInfo);
export const readUserProfile = read(getters.userProfile);
export const readFirstNotification = read(getters.firstNotification);

export const readShopSetting = read(getters.shopSetting);
export const readProducts = read(getters.products);
export const readOrder = read(getters.order);
export const readPaymentMethods = read(getters.paymentMethods);
export const readOnePaymentMethod = read(getters.onePaymentMethod);
export const readFulfillmentTemplates = read(getters.fulfillmentTemplates);
export const readCategories = read(getters.categories);
