var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-card',{staticClass:"pa-2",attrs:{"flat":""}},[_c('v-card-text',{staticClass:"primary--text text--darken-3"},[_c('v-row',{staticClass:"text-h6"},[_vm._v(" "+_vm._s(_vm.question ? _vm.question.content : '')+" ")]),_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(!_vm.question.answer_required),expression:"!question.answer_required"}]},[_c('span',{staticClass:"text-body-1 testQuestions"},[_vm._v("Tuỳ chọn")])]),(_vm.question && !_vm.question.multiple_choice)?[_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(!_vm.question.multiple_choice),expression:"!question.multiple_choice"}]},[_c('v-text-field',{attrs:{"hide-details":"auto","single-line":"","placeholder":"Nhập vào đây","rules":_vm.question.answer_required && !_vm.question.multiple_choice
              ? _vm.requiredRules
              : []},model:{value:(_vm.answers[0].answer_content),callback:function ($$v) {_vm.$set(_vm.answers[0], "answer_content", $$v)},expression:"answers[0].answer_content"}})],1)]:_vm._e(),(
        _vm.question.multiple_choice &&
        _vm.question.choices &&
        _vm.question.choices.length > 0 &&
        _vm.answers.length > 1
      )?[_vm._l((_vm.answers),function(answer,index){return _c('v-container',{key:index,staticClass:"pa-0 mt-4",attrs:{"fluid":""}},[_c('v-card',{staticClass:"hoverable-sheet",class:answer.selected ? 'selected-sheet' : '',attrs:{"outlined":answer.selected,"link":"","disabled":_vm.isLimitOfSelectionReached() && !answer.selected},on:{"click":function($event){return _vm.select(answer)}}},[_c('v-card-text',{staticClass:"ma-0 pa-0"},[_c('v-row',{staticClass:"text-body-1",attrs:{"no-gutters":"","align":"end","justify":"end"}},[_c('v-col',{staticClass:"pa-2",attrs:{"cols":"auto","align-self":"center"}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(answer.choice.content))])]),_c('v-spacer',{directives:[{name:"show",rawName:"v-show",value:(!answer.selected),expression:"!answer.selected"}]}),_c('v-col',{staticClass:"pa-2",attrs:{"cols":"auto","align-self":"center"}},[_c('span',[_vm._v("+ "+_vm._s(_vm._f("vnd")(answer.choice.surcharge)))])]),_c('v-spacer',{directives:[{name:"show",rawName:"v-show",value:(answer.selected),expression:"answer.selected"}]}),_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(answer.selected),expression:"answer.selected"}],staticClass:"pa-2",attrs:{"cols":"auto","align-self":"end"},on:{"click":function($event){$event.stopPropagation();return _vm.stop.apply(null, arguments)}}},[_c('v-btn',{attrs:{"icon":"","disabled":answer.quantity === 0},on:{"click":function($event){return _vm.decreaseQuantity(answer)}}},[_c('v-icon',[_vm._v(_vm._s(_vm.svgPath.mdiMinusBoxOutline))])],1),_c('span',[_vm._v(" "+_vm._s(answer.quantity)+" ")]),_c('v-btn',{attrs:{"icon":"","disabled":(answer.quantity ===
                      _vm.question.limitSelectionOfEachChoice &&
                      _vm.question.limitSelectionOfEachChoice > 0) ||
                    _vm.isLimitOfSelectionReached()},on:{"click":function($event){return _vm.increaseQuantity(answer)}}},[_c('v-icon',[_vm._v(" "+_vm._s(_vm.svgPath.mdiPlusBoxOutline))])],1)],1)],1)],1)],1)],1)}),_c('v-input',{directives:[{name:"show",rawName:"v-show",value:(!_vm.validateMinimumCustomization()),expression:"!validateMinimumCustomization()"}],attrs:{"rules":_vm.minimumCustomizationRules}})]:_vm._e()],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }