import {
  IBuyerInfo,
  IUserProfile,
  IShopSetting,
  IProduct,
  IOrder,
  IPaymentMethod,
  IFulfillmentTemplate,
  ICategory,
} from '@/interfaces';
import { MainState, AppNotification } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';
export const mutations = {
  setToken(state: MainState, payload: string) {
    state.token = payload;
  },
  setBuyerInfo(state: MainState, payload: IBuyerInfo) {
    state.buyerInfo = payload;
  },
  setLoggedIn(state: MainState, payload: boolean) {
    state.isLoggedIn = payload;
  },
  setLogInError(state: MainState, payload: boolean) {
    state.logInError = payload;
  },
  setUserProfile(state: MainState, payload: IUserProfile) {
    state.userProfile = payload;
  },
  setDashboardMiniDrawer(state: MainState, payload: boolean) {
    state.dashboardMiniDrawer = payload;
  },
  setDashboardShowDrawer(state: MainState, payload: boolean) {
    state.dashboardShowDrawer = payload;
  },
  addNotification(state: MainState, payload: AppNotification) {
    state.notifications.push(payload);
  },
  removeNotification(state: MainState, payload: AppNotification) {
    state.notifications = state.notifications.filter(
      (notification) => notification !== payload
    );
  },
  setShopSetting(state: MainState, payload: IShopSetting) {
    state.shopSetting = payload;
  },
  setProduct(state: MainState, payload: IProduct) {
    const products = state.products.filter(
      (product: IProduct) => product.id !== payload.id
    );
    products.push(payload);
    state.products = products;
  },
  setProducts(state: MainState, payload: IProduct[]) {
    state.products = payload;
  },
  setOrder(state: MainState, payload: IOrder) {
    state.order = payload;
  },
  setPaymentMethods(state: MainState, payload: IPaymentMethod[]) {
    state.paymentMethods = payload;
  },
  setFulfillmentTemplates(state: MainState, payload: IFulfillmentTemplate[]) {
    state.fulfillmentTemplates = payload;
  },

  setCategories(state: MainState, payload: ICategory[]) {
    state.categories = payload;
  },
};

const { commit } = getStoreAccessors<MainState | any, State>('');

export const commitSetDashboardMiniDrawer = commit(
  mutations.setDashboardMiniDrawer
);
export const commitSetDashboardShowDrawer = commit(
  mutations.setDashboardShowDrawer
);
export const commitSetLoggedIn = commit(mutations.setLoggedIn);
export const commitSetLogInError = commit(mutations.setLogInError);
export const commitSetToken = commit(mutations.setToken);
export const commitSetBuyerInfo = commit(mutations.setBuyerInfo);
export const commitSetUserProfile = commit(mutations.setUserProfile);
export const commitAddNotification = commit(mutations.addNotification);
export const commitRemoveNotification = commit(mutations.removeNotification);

export const commitSetShopSetting = commit(mutations.setShopSetting);
export const commitSetProduct = commit(mutations.setProduct);
export const commitSetProducts = commit(mutations.setProducts);
export const commitSetOrder = commit(mutations.setOrder);
export const commitSetPaymentMethods = commit(mutations.setPaymentMethods);
export const commitSetFulfillmentTemplates = commit(
  mutations.setFulfillmentTemplates
);
export const commitSetCategories = commit(mutations.setCategories);
