// Import Component hooks before component definitions
import './component-hooks';
import Vue from 'vue';
import vuetify from './plugins/vuetify';

import App from './App.vue';
import router from './router';
import store from '@/store';
import 'fullpage.js/vendors/scrolloverflow';
import VueFullPage from 'vue-fullpage.js';
import './registerServiceWorker';
import AddToCartDialog from './components/AddToCartDialog.vue';
import TruncatedText from './components/TruncatedText.vue';
import TextWriting from './components/TextWriting.vue';
import SectionRow from './components/SectionRow.vue';
import FormattedView from './components/FormattedView.vue';
import './plugins/vuetify-money';
import VuetifyAsyncValidation from 'vuetify-async-validation';
import QuestionSheet from './components/QuestionSheet.vue';
import PreviewCartBottomSheet from './components/PreviewCartBottomSheet.vue';
import PreviewCartButton from './components/PreviewCartButton.vue';
import VImgExt from './components/VImgExt.vue';
import ZaloIcon from '@/components/ZaloIcon.vue';
import vndFilter from '@/filters/vndFilter';
import free20Filter from '@/filters/free20Filter';
Vue.config.productionTip = false;
Vue.use(VuetifyAsyncValidation);
Vue.use(VueFullPage);

Vue.component('add-to-cart-dialog', AddToCartDialog);
Vue.component('truncated-text', TruncatedText);
Vue.component('question-sheet', QuestionSheet);
Vue.component('preview-cart-bottom-sheet', PreviewCartBottomSheet);
Vue.component('preview-cart-button', PreviewCartButton);
Vue.component('v-img-ext', VImgExt);
Vue.component('text-writing', TextWriting);
Vue.component('section-row', SectionRow);
Vue.component('zalo-icon', ZaloIcon);
Vue.component('formatted-view', FormattedView);
router.onError((error) => {
  Vue.prototype.$log.error('Failure Reason: ', error.message, error);
  if (/ChunkLoadError:.*failed./i.test(error.message)) {
    Vue.prototype.$log.error('Reloading Window 1');
    window.location.reload();
  } else if (/Loading.*chunk.*failed./i.test(error.message)) {
    Vue.prototype.$log.error('Reloading Window 2');
    window.location.reload();
  }
});

Vue.config.errorHandler = (err, vm, info) => {
  // console.error('Global error:', err, vm, info);
  if (info === 'callback for watcher "options"') {
    location.reload();
  }
};

new Vue({
  vuetify,
  router,
  store,
  filters: {
    vndFilter,
    free20Filter,
  },
  render: (h) => h(App),
}).$mount('#app');
