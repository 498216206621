import Vue from 'vue';
import Vuetify, {
  VApp,
  VSnackbar,
  VProgressCircular,
  VBtn,
  VContainer,
  VRow,
  VCol,
  VImg,
  VIcon,
  VChip,
  VCard,
  VCardTitle,
  VCardActions,
  VCardText,
  VCardSubtitle,
  VForm,
  VProgressLinear,
  VTextField,
  VAvatar,
  VOverlay,
  VTextarea,
  VHover,
  Touch,
  Resize,
  VMain,
  VToolbar,
  VLayout,
  VToolbarTitle,
  VFooter,
  VBtnToggle,
  VDialog,
  VSpacer,
  VAppBar,
  VAppBarNavIcon,
  VDivider,
  VBottomSheet,
  VExpandTransition,
  VList,
  VListGroup,
  VListItem,
  VListItemSubtitle,
  VListItemContent,
  VListItemTitle,
  VListItemAvatar,
  VListItemIcon,
  VListItemAction,
  VAppBarTitle,
  VSwitch,
  VSheet,
  VCarousel,
  VCarouselItem,
  Intersect,
  VInput,
  VTooltip,
} from 'vuetify/lib';
import colors from 'vuetify/lib/util/colors';

Vue.use(Vuetify, {
  components: {
    VApp,
    VSnackbar,
    VProgressCircular,
    VBtn,
    VContainer,
    VRow,
    VCol,
    VImg,
    VIcon,
    VChip,
    VCard,
    VCardTitle,
    VCardActions,
    VCardText,
    VCardSubtitle,
    VForm,
    VProgressLinear,
    VTextField,
    VAvatar,
    VOverlay,
    VTextarea,
    VHover,
    VMain,
    VToolbar,
    VLayout,
    VToolbarTitle,
    VFooter,
    VBtnToggle,
    VDialog,
    VSpacer,
    VAppBar,
    VAppBarNavIcon,
    VDivider,
    VBottomSheet,
    VExpandTransition,
    VList,
    VListGroup,
    VListItem,
    VListItemSubtitle,
    VListItemContent,
    VListItemTitle,
    VListItemAvatar,
    VListItemIcon,
    VListItemAction,
    VAppBarTitle,
    VSwitch,
    VSheet,
    VCarousel,
    VCarouselItem,
    VInput,
    VTooltip,
  },
  directives: {
    Touch,
    Resize,
    Intersect,
  },
});

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: colors.indigo.darken2,
        secondary: colors.grey.darken3,
        accent: colors.shades.black,
        error: colors.red.accent3,
        background: colors.indigo.lighten5,
      },
      dark: {
        primary: colors.indigo.lighten2,
      },
    },
  },
  icons: {
    iconfont: 'mdiSvg',
  },
});
