<template>
  <div>
    <div v-if="isTruncated">
      {{ truncatedText }}
      <a href="#" @click="showFullText">xem thêm</a>
    </div>
    <div v-else>
      <formatted-view>{{ fullText }}</formatted-view>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class TruncatedText extends Vue {
  @Prop() public fullText!: string; // direct from parent
  @Prop() public maxLength!: number; // direct from parent

  truncatedText = '';
  isTruncated = false;

  mounted() {
    this.truncateText();
  }

  truncateText() {
    if (this.fullText.length > this.maxLength) {
      this.truncatedText = this.fullText.substring(0, this.maxLength) + '...';
      this.isTruncated = true;
    } else {
      this.truncatedText = this.fullText;
      this.isTruncated = false;
    }
  }

  showFullText() {
    this.isTruncated = false;
  }
}
</script>
