import { api } from '@/api';
import router from '@/router';
import {
  getLocalBuyerInfo,
  getLocalToken,
  removeLocalBuyerInfo,
  removeLocalToken,
  saveLocalBuyerInfo,
  saveLocalToken,
} from '@/utils';
import { AxiosError } from 'axios';
import { getStoreAccessors } from 'typesafe-vuex';
import { ActionContext } from 'vuex';
import { State } from '../state';
import { IBuyerInfo, ICategory, IOrder } from '@/interfaces';
import {
  commitAddNotification,
  commitRemoveNotification,
  commitSetLoggedIn,
  commitSetLogInError,
  commitSetToken,
  commitSetUserProfile,
  commitSetShopSetting,
  commitSetBuyerInfo,
  commitSetProducts,
  commitSetOrder,
  commitSetPaymentMethods,
  commitSetFulfillmentTemplates,
  commitSetCategories,
} from './mutations';
import { AppNotification, MainState } from './state';
type MainContext = ActionContext<MainState, State>;

export const actions = {
  async actionLogIn(
    context: MainContext,
    payload: { username: string; password: string }
  ) {
    try {
      const response = await api.logInGetToken(
        payload.username,
        payload.password
      );
      const token = response.data.access_token;
      if (token) {
        saveLocalToken(token);
        commitSetToken(context, token);
        commitSetLoggedIn(context, true);
        commitSetLogInError(context, false);
        await dispatchGetUserProfile(context);
        await dispatchRouteLoggedIn(context);
        commitAddNotification(context, {
          content: 'Đã đăng nhập',
          color: 'success',
        });
      } else {
        await dispatchLogOut(context);
      }
    } catch (err) {
      commitSetLogInError(context, true);
      await dispatchLogOut(context);
    }
  },

  async actionGetUserProfile(context: MainContext) {
    try {
      const response = await api.getMe(context.state.token);
      if (response.data) {
        commitSetUserProfile(context, response.data);
      }
    } catch (error: any) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionUpdateUserProfile(context: MainContext, payload) {
    try {
      const loadingNotification = { content: 'saving', showProgress: true };
      commitAddNotification(context, loadingNotification);
      const response = (
        await Promise.all([
          api.updateMe(context.state.token, payload),
          await new Promise<void>((resolve, reject) =>
            setTimeout(() => resolve(), 300)
          ),
        ])
      )[0];
      commitSetUserProfile(context, response.data);
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, {
        content: 'Cập nhật thông tin thành công',
        color: 'success',
      });
    } catch (error: any) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionCheckLoggedIn(context: MainContext) {
    if (!context.state.isLoggedIn) {
      let token = context.state.token;
      if (!token) {
        const localToken = getLocalToken();
        if (localToken) {
          commitSetToken(context, localToken);
          token = localToken;
        }
      }
      if (token) {
        try {
          const response = await api.getMe(token);
          commitSetLoggedIn(context, true);
          commitSetUserProfile(context, response.data);
        } catch (error: any) {
          await dispatchRemoveLogIn(context);
        }
      } else {
        await dispatchRemoveLogIn(context);
      }
    }
  },
  async actionRemoveLogIn(context: MainContext) {
    removeLocalToken();
    commitSetToken(context, '');
    commitSetLoggedIn(context, false);
  },
  async actionLogOut(context: MainContext) {
    await dispatchRemoveLogIn(context);
    await dispatchRouteLogOut(context);
  },
  async actionUserLogOut(context: MainContext) {
    await dispatchLogOut(context);
    commitAddNotification(context, { content: 'Logged out', color: 'success' });
  },
  actionRouteLogOut(context: MainContext) {
    if (router.currentRoute.path !== '/login') {
      router.push('/login');
    }
  },
  async actionCheckApiError(context: MainContext, payload: AxiosError) {
    if (payload.response!.status === 401) {
      await dispatchLogOut(context);
    } else if (payload.response!.status === 300) {
      commitAddNotification(context, {
        color: 'error',
        content: 'Lỗi máy chủ Backend',
      });
    } else if (
      payload.response!.status === 422 ||
      payload.response!.status === 400
    ) {
      const errorMsg = payload.response!.data.detail;
      commitAddNotification(context, { color: 'error', content: errorMsg });
      throw new Error(errorMsg);
    }
  },
  async actionCheckEmailAvailablity(
    context: MainContext,
    payload: { email: string }
  ) {
    const response = (
      await Promise.all([
        api.checkEmailAvailability(payload.email),
        await new Promise<void>((resolve, reject) =>
          setTimeout(() => resolve(), 300)
        ),
      ])
    )[0];
    return response.data;
  },

  async actionCheckDomainAvailablity(
    context: MainContext,
    payload: { subDomainName: string }
  ) {
    const response = (
      await Promise.all([
        api.checkDomainlAvailability(payload.subDomainName),
        await new Promise<void>((resolve, reject) =>
          setTimeout(() => resolve(), 300)
        ),
      ])
    )[0];
    return response.data;
  },

  async actionSaveLocalBuyerInfoLocal(
    context: MainContext,
    payload: IBuyerInfo
  ) {
    saveLocalBuyerInfo(payload);
    commitSetBuyerInfo(context, payload);
  },

  async actionGetLocalBuyerInfo(context: MainContext) {
    const buyerInfo = getLocalBuyerInfo();
    commitSetBuyerInfo(context, buyerInfo);
  },

  async actionRemoveLocalBuyerInfo(context: MainContext) {
    removeLocalBuyerInfo();
    commitSetBuyerInfo(context, {
      email: '',
      name: '',
      phone: '',
    });
  },

  actionRouteLoggedIn(context: MainContext) {
    if (
      router.currentRoute.path === '/login' ||
      router.currentRoute.path === '/'
    ) {
      router.push('/main').catch((error) => error);
    }
  },
  async removeNotification(
    context: MainContext,
    payload: { notification: AppNotification; timeout: number }
  ) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        commitRemoveNotification(context, payload.notification);
        resolve(true);
      }, payload.timeout);
    });
  },
  async addNotification(
    context: MainContext,
    payload: { notification: AppNotification; timeout: number }
  ) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        commitAddNotification(context, payload.notification);
        resolve(true);
      }, payload.timeout);
    });
  },
  async passwordRecovery(context: MainContext, payload: { username: string }) {
    const loadingNotification = {
      content: 'Đang gửi email khôi phục mật khẩu',
      showProgress: true,
    };
    try {
      commitAddNotification(context, loadingNotification);
      const response = (
        await Promise.all([
          api.passwordRecovery(payload.username),
          await new Promise<void>((resolve, reject) =>
            setTimeout(() => resolve(), 300)
          ),
        ])
      )[0];
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, {
        content: 'Email khôi phục mật khẩu đã được gửi',
        color: 'success',
      });
      await dispatchLogOut(context);
    } catch (error: any) {
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, {
        color: 'error',
        content: 'Tên đăng nhập không đúng',
      });
    }
  },
  async resetPassword(
    context: MainContext,
    payload: { password: string; token: string }
  ) {
    const loadingNotification = {
      content: 'Đang đặt lại mật khẩu',
      showProgress: true,
    };
    try {
      commitAddNotification(context, loadingNotification);
      const response = (
        await Promise.all([
          api.resetPassword(payload.password, payload.token),
          await new Promise<void>((resolve, reject) =>
            setTimeout(() => resolve(), 300)
          ),
        ])
      )[0];
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, {
        content: 'Mật khẩu đã được đặt lại thành công',
        color: 'success',
      });
      await dispatchLogOut(context);
    } catch (error: any) {
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, {
        color: 'error',
        content: 'Lỗi khi đặt lại mật khẩu',
      });
    }
  },
  async actionGetShopSetting(context: MainContext) {
    try {
      const response = (
        await Promise.all([
          api.getShopSettings(),
          await new Promise<void>((resolve, reject) =>
            setTimeout(() => resolve(), 300)
          ),
        ])
      )[0];
      commitSetShopSetting(context, response.data[0]);
    } catch (error: any) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionUploadPhotos(context: MainContext, payload: FormData) {
    const loadingNotification = { content: 'saving', showProgress: true };
    try {
      // commitAddNotification(context, loadingNotification);
      const response = (
        await Promise.all([
          api.uploadPhotos(payload),
          await new Promise<void>((resolve, reject) =>
            setTimeout(() => resolve(), 300)
          ),
        ])
      )[0];
      // commitSetSite(context, response.data);
      // commitRemoveNotification(context, loadingNotification);
      // commitAddNotification(context, {
      //   content: 'Photo successfully uploaded',
      //   color: 'success',
      // });
      return response.data;
    } catch (error: any) {
      // commitRemoveNotification(context, loadingNotification);
      await dispatchCheckApiError(context, error);
    }
  },
  async actionGetProducts(context: MainContext) {
    try {
      const response = await api.getProducts();
      if (response) {
        commitSetProducts(context, response.data);
      }
    } catch (error: any) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionCreateOrder(context: MainContext, payload: IOrder) {
    const loadingNotification = { content: 'saving', showProgress: true };
    try {
      commitAddNotification(context, loadingNotification);
      const response = (
        await Promise.all([
          api.createOrder(payload),
          await new Promise<void>((resolve, reject) =>
            setTimeout(() => resolve(), 300)
          ),
        ])
      )[0];
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, {
        content: 'Đơn hàng đã được đặt thành công',
        color: 'success',
      });
      commitSetOrder(context, response.data);
    } catch (error: any) {
      commitRemoveNotification(context, loadingNotification);
      await dispatchCheckApiError(context, error);
    }
  },
  async actionGetOrder(context: MainContext, orderId: string) {
    try {
      const response = await api.getOrder(orderId);
      if (response) {
        commitSetOrder(context, response.data);
      }
    } catch (error: any) {
      await dispatchCheckApiError(context, error);
    }
  },

  async actionGetOrderByPublicId(context: MainContext, publicId: string) {
    try {
      const response = await api.getOrderByPublicId(publicId);
      if (response) {
        commitSetOrder(context, response.data);
      }
    } catch (error: any) {
      await dispatchCheckApiError(context, error);
    }
  },

  async actionGetPaymentMethods(context: MainContext) {
    try {
      const response = (
        await Promise.all([
          api.getPaymentMethods(),
          await new Promise<void>((resolve, reject) =>
            setTimeout(() => resolve(), 300)
          ),
        ])
      )[0];
      commitSetPaymentMethods(context, response.data);
    } catch (error: any) {
      await dispatchCheckApiError(context, error);
    }
  },

  async actionGetCategories(context: MainContext) {
    try {
      const response = (
        await Promise.all([
          api.getCategories(),
          await new Promise<void>((resolve, reject) =>
            setTimeout(() => resolve(), 300)
          ),
        ])
      )[0];
      commitSetCategories(context, response.data);
    } catch (error: any) {
      await dispatchCheckApiError(context, error);
    }
  },

  async actionGetFulfillmentTemplates(context: MainContext) {
    try {
      const response = (
        await Promise.all([
          api.getFulfillmentTemplates(),
          await new Promise<void>((resolve, reject) =>
            setTimeout(() => resolve(), 300)
          ),
        ])
      )[0];
      commitSetFulfillmentTemplates(context, response.data);
    } catch (error: any) {
      await dispatchCheckApiError(context, error);
    }
  },

  async actionGetBankList() {
    const response = await api.getBankList();
    if (response) {
      return response.data.data;
    } else {
      return [];
    }
  },
};

const { dispatch } = getStoreAccessors<MainState | any, State>('');

export const dispatchCheckApiError = dispatch(actions.actionCheckApiError);
export const dispatchCheckLoggedIn = dispatch(actions.actionCheckLoggedIn);
export const dispatchGetUserProfile = dispatch(actions.actionGetUserProfile);
export const dispatchLogIn = dispatch(actions.actionLogIn);
export const dispatchLogOut = dispatch(actions.actionLogOut);
export const dispatchUserLogOut = dispatch(actions.actionUserLogOut);
export const dispatchRemoveLogIn = dispatch(actions.actionRemoveLogIn);
export const dispatchRouteLoggedIn = dispatch(actions.actionRouteLoggedIn);
export const dispatchRouteLogOut = dispatch(actions.actionRouteLogOut);
export const dispatchUpdateUserProfile = dispatch(
  actions.actionUpdateUserProfile
);
export const dispatchRemoveNotification = dispatch(actions.removeNotification);
export const dispatchAddNotification = dispatch(actions.addNotification);
export const dispatchPasswordRecovery = dispatch(actions.passwordRecovery);
export const dispatchResetPassword = dispatch(actions.resetPassword);
export const dispatchGetShopSetting = dispatch(actions.actionGetShopSetting);
export const dispatchCheckEmailAvailablity = dispatch(
  actions.actionCheckEmailAvailablity
);
export const dispatchCheckDomainAvailablity = dispatch(
  actions.actionCheckDomainAvailablity
);
export const dispatchUploadPhotos = dispatch(actions.actionUploadPhotos);
export const dispatchGetProducts = dispatch(actions.actionGetProducts);
export const dispatchCreateOrder = dispatch(actions.actionCreateOrder);
export const dispatchGetPaymentMethods = dispatch(
  actions.actionGetPaymentMethods
);
export const dispatchGetFulfillmentTemplates = dispatch(
  actions.actionGetFulfillmentTemplates
);
export const dispatchGetOrder = dispatch(actions.actionGetOrder);
export const dispatchGetOrderByPublicId = dispatch(
  actions.actionGetOrderByPublicId
);
export const dispatchSaveLocalBuyerInfo = dispatch(
  actions.actionSaveLocalBuyerInfoLocal
);
export const dispatchRemoveLocalBuyerInfo = dispatch(
  actions.actionRemoveLocalBuyerInfo
);
export const dispatchGetLocalBuyerInfo = dispatch(
  actions.actionGetLocalBuyerInfo
);
export const dispatchGetCategories = dispatch(actions.actionGetCategories);
export const dispatchGetBankList = dispatch(actions.actionGetBankList);
