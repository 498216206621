<template>
  <span>{{ content }}</span>
</template>
<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class TextWriting extends Vue {
  @Prop({ default: 100 })
  public speed!: number; /* The speed/duration of the effect in milliseconds */
  @Prop({ default: 'Type here' })
  public text!: string; /* The content of the element */
  textIndex = 0;
  content: string = '';

  public typeWriter() {
    if (this.textIndex < this.text.length) {
      this.content += this.text.charAt(this.textIndex);
      this.textIndex++;
      setTimeout(this.typeWriter, this.speed);
    }
  }

  mounted() {
    this.typeWriter();
  }
}
</script>
