<template>
  <div class="section" data-centered="true">
    <v-container
      :class="containerClass"
      ref="sectionRow"
      style="max-width: inherit"
      no-gutters
    >
      <v-row no-gutters style="height: 96vh" justify="center">
        <v-col align-self="center" class="mt-6 width-650" ref="sectionCol">
          <slot></slot>
          <v-container style="height: 100px"></v-container>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
@Component
export default class SectionRow extends Vue {
  topOfTheDivScrolledTime = 0;
  lockScroll = false;

  containerClass = '';

  get isOverflowing() {
    const sectionColElement = this.$refs.sectionCol as HTMLElement;
    return sectionColElement.offsetHeight > window.innerHeight;
  }

  isOverflow = false;
  vFooterHeight = 60;

  async updated() {
    await this.$nextTick();
    const sectionColElement = this.$refs.sectionCol as HTMLElement;
    const sectionRowElement = this.$refs.sectionRow as HTMLElement;
    if (
      sectionColElement.offsetHeight >
        window.innerHeight - this.vFooterHeight &&
      !this.isOverflow
    ) {
      this.containerClass = 'scrollable-content';
      this.isOverflow = true;
    }
  }

  // handleMouseWheel = (e: WheelEvent) => {
  //   if (this.isOverflowing) {
  //     // console.log('The div is higher than the viewport');
  //     e.stopPropagation();
  //     const sectionRowElement = this.$refs.sectionRow as HTMLElement;
  //     const sectionColElement = this.$refs.sectionCol as HTMLElement;
  //     // scroll down
  //     if (sectionColElement) {
  //       console.log(
  //         ' scrollTop: ',
  //         sectionRowElement.scrollTop +
  //           ' , innerHeight: ' +
  //           window.innerHeight +
  //           ' , offsetHeight: ' +
  //           sectionColElement.offsetHeight
  //       );
  //       if (
  //         (e as WheelEvent).deltaY > 0 &&
  //         sectionRowElement.scrollTop + window.innerHeight ===
  //           sectionRowElement.scrollHeight
  //       ) {
  //         console.log('scrolled to bottom of inner div');
  //         this.$emit('moveSectionDown');
  //       }

  //       // scroll up
  //       if ((e as WheelEvent).deltaY < 0 && sectionRowElement.scrollTop === 0) {
  //         console.log('scrolled to top of inner div');
  //         this.topOfTheDivScrolledTime++;
  //         let limitation = 20;
  //         if (this.detectTrackPad(e)) {
  //           // User scrolled with a trackpad
  //           limitation = 100;
  //         }
  //         if (this.topOfTheDivScrolledTime >= limitation && !this.lockScroll) {
  //           this.lockScroll = true;
  //           this.$emit('moveSectionUp');

  //           this.topOfTheDivScrolledTime = 0;
  //           setTimeout(() => {
  //             this.lockScroll = false;
  //           }, 4000);
  //         }
  //       }
  //     }
  //   }
  // };

  // handleScroll(e) {
  //   const sectionRowElement = this.$refs.sectionRow as HTMLElement;
  //   const isOverflowing = sectionRowElement.offsetHeight > window.innerHeight;
  //   if (isOverflowing) {
  //     e.stopPropagation();
  //   }
  // }

  // handleTouchMove(e) {
  //   const sectionRowElement = this.$refs.sectionRow as HTMLElement;
  //   const isOverflowing = sectionRowElement.offsetHeight > window.innerHeight;
  //   if (isOverflowing) {
  //     e.stopPropagation();
  //     sectionRowElement.scrollTop +=
  //       e.touches[0].clientY - e.touches[0].clientY;
  //     if (sectionRowElement.scrollTop === 0) {
  //       this.topOfTheDivScrolledTime++;
  //       if (this.topOfTheDivScrolledTime >= 3 && !this.lockScroll) {
  //         this.lockScroll = true;
  //         this.$emit('moveSectionUp');
  //         this.topOfTheDivScrolledTime = 0;
  //         setTimeout(() => {
  //           this.lockScroll = false;
  //         }, 2000);
  //       }
  //     }
  //   }
  // }

  detectTrackPad(e) {
    let isTrackpad = false;
    if (e.wheelDeltaY) {
      if (e.wheelDeltaY === e.deltaY * -3) {
        isTrackpad = true;
      }
    } else if (e.deltaMode === 0) {
      isTrackpad = true;
    }
    return isTrackpad;
  }
}
</script>

<style scoped>
.scrollable-content {
  overflow: scroll;
}
</style>
