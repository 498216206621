import { IBuyerInfo } from '@/interfaces';

export const getLocalToken = () => localStorage.getItem('token');

export const saveLocalToken = (token: string) =>
  localStorage.setItem('token', token);

export const removeLocalToken = () => localStorage.removeItem('token');

export const saveLocalBuyerInfo = (buyerInfo: IBuyerInfo) =>
  localStorage.setItem('buyerInfo', JSON.stringify(buyerInfo));

export const getLocalBuyerInfo = () => {
  const buyerInfo = localStorage.getItem('buyerInfo');
  return buyerInfo ? JSON.parse(buyerInfo) : null;
};

export const getKeyByValue = (object: any, value: any) => {
  return Object.keys(object).find((key) => object[key] === value);
};

export const removeLocalBuyerInfo = () => localStorage.removeItem('buyerInfo');

import {
  CF_IMAGES_ACC_HASH,
  IMAGES_VARIANT_PUBLIC,
  IMAGES_VARIANT_THUMBNAIL,
} from '@/env';

export const getImageUrl = (uuid: string) =>
  `https://imagedelivery.net/${CF_IMAGES_ACC_HASH}/${uuid}/${IMAGES_VARIANT_PUBLIC}`;

export const getThumbnailUrl = (uuid: string) =>
  `https://imagedelivery.net/${CF_IMAGES_ACC_HASH}/${uuid}/${IMAGES_VARIANT_THUMBNAIL}`;
