import axios from 'axios';
import { apiUrlV1 } from '@/env';
import { IOrder } from './interfaces/index';
import {
  IUserProfile,
  IUserProfileUpdate,
  IUserProfileCreate,
  IShop,
  IShopCreate,
  IShopUpdate,
} from './interfaces';

function authHeaders(token: string) {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}

export const api = {
  // Users API
  async logInGetToken(username: string, password: string) {
    const params = new URLSearchParams();
    params.append('username', username);
    params.append('password', password);

    return axios.post(`${apiUrlV1}/login/access-token`, params);
  },
  async getMe(token: string) {
    return axios.get<IUserProfile>(`${apiUrlV1}/users/me`, authHeaders(token));
  },
  async updateMe(token: string, data: IUserProfileUpdate) {
    return axios.put<IUserProfile>(
      `${apiUrlV1}/users/me`,
      data,
      authHeaders(token)
    );
  },
  async getUsers(token: string) {
    return axios.get<IUserProfile[]>(`${apiUrlV1}/users/`, authHeaders(token));
  },
  async updateUser(token: string, userId: number, data: IUserProfileUpdate) {
    return axios.put(`${apiUrlV1}/users/${userId}`, data, authHeaders(token));
  },
  async createUser(token: string, data: IUserProfileCreate) {
    return axios.post(`${apiUrlV1}/users/`, data, authHeaders(token));
  },
  async checkEmailAvailability(email: string) {
    return axios.get(`${apiUrlV1}/users/validate/email`, {
      params: { email },
    });
  },
  // Shops API
  async getShops(token: string) {
    return axios.get<IShop[]>(`${apiUrlV1}/shops/`, authHeaders(token));
  },
  async updateShop(token: string, shopId: number, data: IShopUpdate) {
    return axios.put(`${apiUrlV1}/shops/${shopId}`, data, authHeaders(token));
  },
  async getShopSettings() {
    return axios.get(`${apiUrlV1}/shopSettings/`);
  },
  // Password reset API
  async passwordRecovery(email: string) {
    return axios.post(`${apiUrlV1}/password-recovery/${email}`);
  },
  async resetPassword(password: string, token: string) {
    return axios.post(`${apiUrlV1}/reset-password/`, {
      new_password: password,
      token,
    });
  },
  // Domains API
  async checkDomainlAvailability(subDomainName: string) {
    return axios.get(`${apiUrlV1}/domains/check/availability`, {
      params: { sub_domain_name: subDomainName },
    });
  },
  // Sites API
  async uploadPhotos(data: FormData) {
    return axios.post(`${apiUrlV1}/utils/upload/photos`, data);
  },

  async getProducts() {
    return axios.get(`${apiUrlV1}/products/`);
  },

  async createOrder(data: IOrder) {
    return axios.post(`${apiUrlV1}/orders/`, data);
  },
  async getOrders(token: string) {
    return axios.get(`${apiUrlV1}/orders/`, authHeaders(token));
  },
  async getOrder(orderId: string) {
    return axios.get(`${apiUrlV1}/orders/${orderId}/`);
  },
  async getOrderByPublicId(publicId: string) {
    return axios.get(`${apiUrlV1}/orders/public/${publicId}/`);
  },

  async getPaymentMethods() {
    return axios.get(`${apiUrlV1}/paymentMethods/`);
  },

  async getFulfillmentTemplates() {
    return axios.get(`${apiUrlV1}/fulfillmentTemplates/`);
  },

  async getCategories() {
    return axios.get(`${apiUrlV1}/categories/`);
  },
  // get bank list from VietQR
  async getBankList() {
    return axios.get('https://api.vietqr.io/v2/banks');
  },
};
