<template>
  <v-bottom-sheet
    max-width="650"
    inset
    v-model="previewCartSheet"
    class="mx-auto"
    @click:outside="closePreviewCartSheet"
  >
    <v-card class="mx-auto">
      <v-app-bar flat color="rgba(0, 0, 0, 0)">
        <v-app-bar-title>Đơn hàng của bạn</v-app-bar-title>

        <v-spacer></v-spacer>

        <span>{{ calculateTotalPrice() | vnd | free20 }}</span>
      </v-app-bar>
      <v-divider></v-divider>
      <v-list>
        <template v-for="(cartItem, index) in shoppingCart.cart_items">
          <v-list-item :key="index">
            <v-list-item-avatar
              size="46"
              rounded
              @touchstart="onTouchStart"
              @touchend="onTouchEnd"
            >
              <v-img :src="cartItem.thumbnail_url"></v-img>
            </v-list-item-avatar>
            <v-list-item-content
              @touchstart="onTouchStart"
              @touchend="onTouchEnd"
            >
              <v-list-item-title class="text-body-1">{{
                cartItem.product_name
              }}</v-list-item-title>
              <v-list-item-subtitle class="text-body-2">{{
                cartItem.unit_price | vnd
              }}</v-list-item-subtitle>
            </v-list-item-content>
            <v-btn
              x-small
              v-show="!isLongTouch"
              class="ma-0 py-5"
              @click="decreaseProductQuantity(cartItem)"
              outlined
              :disabled="cartItem.quantity <= 0"
            >
              <v-icon>{{ svgPath.mdiMinus }}</v-icon>
            </v-btn>

            <span v-show="!isLongTouch" class="text-body-1 ma-2">{{
              cartItem.quantity
            }}</span>
            <v-btn
              v-show="!isLongTouch"
              x-small
              class="ma-0 py-5"
              outlined
              @click="increaseProductQuantity(cartItem)"
            >
              <v-icon>{{ svgPath.mdiPlus }}</v-icon>
            </v-btn>
          </v-list-item>
        </template>
        <v-form
          ref="cartItemValidation"
          v-model="valid"
          @submit.prevent="handleFormSubmit"
          lazy-validation
        >
          <v-input
            class="mt-n8 ma-2"
            :rules="noEmtpyCartRules"
            v-model="shoppingCart.cart_items"
            v-show="!valid"
          >
          </v-input>
        </v-form>
      </v-list>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn
          large
          block
          color="primary lighten-1"
          @click="handleFormSubmit()"
          :disabled="!valid"
          >Tiến hành thanh toán</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-bottom-sheet>
</template>

<script lang="ts">
import {
  Component,
  Vue,
  Prop,
  Emit,
  Model,
  Watch,
} from 'vue-property-decorator';
import { mdiPlus, mdiMinus } from '@mdi/js';
import { IShoppingCart, IShoppingCartItem } from '@/interfaces';
@Component
export default class PreviewCartBottomSheet extends Vue {
  @Prop(String) public color: string | undefined;
  @Prop({ default: false }) public previewCartSheet?: boolean | undefined;
  @Model() public shoppingCart!: IShoppingCart;

  valid = true;

  svgPath = {
    mdiPlus,
    mdiMinus,
  };

  touchTimeout: number | undefined;
  isLongTouch = false;

  onTouchStart() {
    // Long touch event
    console.log('Long touch!');
    this.isLongTouch = true;
  }

  onTouchEnd() {
    this.isLongTouch = false;
    clearTimeout(this.touchTimeout);
  }

  validate() {
    if (this.$refs.cartItemValidation) {
      this.valid = (
        this.$refs.cartItemValidation as Vue & { validate: () => boolean }
      ).validate();
    }

    return this.valid;
  }

  @Emit('emptyCart')
  emptyCart() {
    return true;
  }

  handleFormSubmit() {
    if (this.validate()) {
      this.$emit('submit');
    }
  }

  @Emit('close')
  closePreviewCartSheet() {
    if (!this.valid) {
      this.emptyCart();
    }
    return true;
  }

  @Watch('shoppingCart.total_price')
  onTotalPriceChange() {
    this.validate();
  }

  public increaseProductQuantity(item: IShoppingCartItem) {
    item.quantity++;
    this.shoppingCart.total_price = this.calculateTotalPrice();
  }

  public decreaseProductQuantity(item: IShoppingCartItem) {
    if (item.quantity > 0) {
      item.quantity--;
      this.shoppingCart.total_price = this.calculateTotalPrice();
    }
  }

  public noEmtpyCartRules = [
    (v: IShoppingCartItem[]) => {
      const validation = this.checkIfEmptyCart(v);
      if (!validation) {
        return 'Vui lòng thêm sản phẩm vào giỏ hàng của bạn';
      }
      return validation;
    },
  ];

  checkIfEmptyCart(cartItems: IShoppingCartItem[]) {
    let cartItemsFiltered = cartItems.filter((item) => item.quantity > 0);
    if (cartItemsFiltered.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  calculateTotalPrice() {
    return this.shoppingCart.cart_items.reduce((total, item) => {
      item.total_price = item.unit_price! * item.quantity!;
      return total + item.total_price;
    }, 0);
  }
}
</script>

<style scoped></style>
