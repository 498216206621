<script lang="ts">
import { VImg } from 'vuetify/lib';

import { Component, Prop } from 'vue-property-decorator';

@Component({})
export default class VImgExt extends VImg {
  @Prop({ default: 600 }) public maxDimension!: number;
  imgConf = {
    maxHeight: this.maxDimension,
    maxWidth: this.maxDimension,
  };

  image!: any;
  src!: any;

  getSrc: any;
  isLoading: any;
  normalisedSrc: any;
  calculatedAspectRatio: any;

  measurableStyles: any;
  maxWidth: any;
  maxHeight: any;
  setWidthHeightConstrain() {
    if (this.image.naturalWidth > this.image.naturalHeight) {
      if (this.image.naturalWidth > this.maxDimension) {
        this.imgConf.maxWidth = this.maxDimension;
      } else {
        this.imgConf.maxWidth = this.image.naturalWidth;
      }
      this.imgConf.maxHeight =
        (this.image.naturalWidth * this.imgConf.maxWidth) /
        this.image.naturalHeight;
    } else {
      if (this.image.naturalHeight > this.maxDimension) {
        this.imgConf.maxHeight = this.maxDimension;
      } else {
        this.imgConf.maxHeight = this.image.naturalHeight;
      }
      this.imgConf.maxWidth =
        (this.image.naturalWidth * this.imgConf.maxHeight) /
        this.image.naturalHeight;
    }
  }

  onLoad() {
    this.getSrc();
    this.isLoading = false;

    const viewportWidth = window.innerWidth;
    if (this.maxDimension > viewportWidth) {
      this.imgConf.maxWidth = viewportWidth;
      this.imgConf.maxHeight = viewportWidth;
    } else {
      this.setWidthHeightConstrain();
    }

    this.$emit('setDimension', this.imgConf);
    this.$emit('load', this.src);

    if (
      this.image &&
      (this.normalisedSrc.src.endsWith('.svg') ||
        this.normalisedSrc.src.startsWith('data:image/svg+xml'))
    ) {
      if (this.image.naturalHeight && this.image.naturalWidth) {
        this.image.naturalWidth = this.image.naturalWidth;
        this.calculatedAspectRatio =
          this.image.naturalWidth / this.image.naturalHeight;
      } else {
        this.calculatedAspectRatio = 1;
      }
    }
  }
}
</script>
